import React, { useState, useEffect } from 'react';
import mergeImages from 'merge-images';
import backgroundPresets from './presets';
import { Container, Row, Col } from 'react-bootstrap';
import '../fonts.css';
const { createCanvas } = require('canvas');

async function textToImage(text, fontSize, fontFamily, fontColor, letterSpacing, maxWidth, lineHeight, bgColor, margin, maxRows, textOpacity = 1) {
  return new Promise((resolve) => {
    var canvas = createCanvas(maxWidth, 1000);
    const ctx = canvas.getContext('2d');
    // Disable anti-aliasing
    ctx.imageSmoothingEnabled = false
    // Set background color
    ctx.fillStyle = bgColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.globalAlpha = textOpacity;

    // Set font properties
    ctx.fillStyle = fontColor;// Set text color
    ctx.font = `${fontSize}px ${fontFamily}`;
    ctx.textBaseline = 'alphabetic';

    let x = margin;  // Start x-coordinate with margin
    let y = margin;  // Start y-coordinate with margin
    let lines = 1;

    const words = text.split(' ');

    let lastWordX = 0;

    for (const word of words) {
      const wordWidth = ctx.measureText(word).width;

      // Check if the word exceeds the maximum width considering the margin
      if (x + wordWidth + margin > maxWidth) {
        x = margin;
        y += lineHeight;
        lines++;

        if (maxRows && lines > maxRows) {
          break;
        }
        lastWordX = x;
      }

      // Draw the word
      for (let i = 0; i < word.length; i++) {
        const char = word[i];
        const charWidth = ctx.measureText(char).width;
        ctx.fillText(char, x, y + fontSize);
        x += charWidth + letterSpacing;
      }

      // Add space after the word unless it's the last word
      if (word !== words[words.length - 1]) {
        const spaceWidth = ctx.measureText(' ').width;
        ctx.fillText(' ', x, y);
        x += spaceWidth + letterSpacing;
      }
      if (word === words[words.length - 1]) {
        lastWordX = x;
      }
    }

    // Trim the canvas to fit the text and margin
    const imageData = ctx.getImageData(0, 0, maxWidth, lines * lineHeight + 2 * margin);
    const trimmedCanvas = createCanvas(maxWidth, lines * lineHeight + 2 * margin);
    const trimmedCtx = trimmedCanvas.getContext('2d');
    trimmedCtx.putImageData(imageData, 0, 0);


    // Convert the trimmed canvas to a Data URI
    const dataURI = trimmedCanvas.toDataURL('image/png');




    const lastWordEndX = lastWordX;
    const lastWordY = y;




    // Now resolve with both the data URI and the end position
    resolve({ dataURI, lastWordEndX, lastWordY });
  });
}
async function addWatermark(text, image, settings) {
  const { dataURI } = await textToImage(text, settings.watermarkFontSize, settings.fontFamily, settings.fontColor, 0, settings.maxWidth, settings.lineHeight, 'transparent', settings.margin, settings.maxRows, settings.watermarkOpacity);
  return await mergeImages([image, { src: dataURI }]);
}

async function mergeTextWithImage(text, imagePath, settings) {
  console.log(imagePath, settings.trailingImage);
  if (!text) {
    text = settings.defaultText;
  }
  const { dataURI, lastWordEndX, lastWordY } = await textToImage(text, settings.fontSize, settings.fontFamily, settings.fontColor, 0, settings.maxWidth, settings.lineHeight, 'transparent', settings.margin, settings.maxRows);
  if (settings.shadowColor) {
    // Measure the width of a capital 'W' in the given font size
    const canvas = createCanvas(100, 100); // Temporary canvas for measuring text
    const ctx = canvas.getContext('2d');
    ctx.font = `${settings.fontSize}px ${settings.fontFamily}`;
    const capital_W_Width = ctx.measureText('W').width;

    // Define the shadow offsets based on the measured width
    const shadowOffset = Math.floor(capital_W_Width / 5) - 1;

    // Create the background text with shadow settings
    const { dataURI: backgroundText } = await textToImage(
      text,
      settings.fontSize,
      settings.fontFamily,
      settings.shadowColor,
      0,
      settings.maxWidth,
      settings.lineHeight,
      'transparent',
      settings.margin,
      settings.maxRows
    );

    //place shadowing background of the text
    const stage1 = await mergeImages(
      [imagePath, { src: backgroundText, x: settings.anchorX + shadowOffset, y: settings.anchorY }],
    );
    const stage2 = await mergeImages(
      [stage1, { src: backgroundText, x: settings.anchorX, y: settings.anchorY + shadowOffset }],
    );
    const stage3 = await mergeImages(
      [stage2, { src: backgroundText, x: settings.anchorX + shadowOffset, y: settings.anchorY + shadowOffset }],
    );
    const stage4 = await mergeImages(
      [stage3, { src: backgroundText, x: settings.anchorX + shadowOffset / 2, y: settings.anchorY }],
    );
    const stage5 = await mergeImages(
      [stage4, { src: backgroundText, x: settings.anchorX, y: settings.anchorY + shadowOffset / 2 }],
    );
    const stage6 = await mergeImages(
      [stage5, { src: backgroundText, x: settings.anchorX + shadowOffset, y: settings.anchorY + shadowOffset / 2 }],
    );
    const stage7 = await mergeImages(
      [stage6, { src: backgroundText, x: settings.anchorX + shadowOffset / 2, y: settings.anchorY + shadowOffset }],
    );
    const finalBgImg = await mergeImages(
      [stage7, { src: backgroundText, x: settings.anchorX, y: settings.anchorY }],
    );
    const finalImg = await mergeImages([finalBgImg, { src: dataURI, x: settings.anchorX, y: settings.anchorY }]);
    if (settings.trailingImage) {
      return await mergeImages([finalImg, { src: settings.trailingImage, x: lastWordEndX + settings.trailingXoffset + settings.anchorX, y: settings.anchorY + lastWordY + settings.trailingYoffset }]);
    }
    return finalImg;
  } else {
    return mergeImages(
      [imagePath, { src: dataURI, x: settings.anchorX, y: settings.anchorY }],
    );
  }



}


const ImageProcessor = () => {
  const [text, setText] = useState('');
  const [placeholderText, setPlaceholderText] = useState('Enter your text here...');
  const [background, setBackground] = useState(backgroundPresets[0].src); // Default background
  const [settings, setSettings] = useState(backgroundPresets[0].settings); // Default settings
  const [displayedImage, setDisplayedImage] = useState(backgroundPresets[0].src);
  const [fontLoaded, setFontLoaded] = useState(false);

  // Loading the font only once using useEffect
  useEffect(() => {
    const loadFont = async () => {
      if ('fonts' in document) {
        try {
          for (const font of backgroundPresets.map(preset => preset.settings.fontFamily)) {
            await document.fonts.load(`1em "${font}"`);
          }
          setFontLoaded(true);
        } catch (error) {
          console.error('Font failed to load', error);
        }
      }
    };

    loadFont();
    setPlaceholderText(settings.defaultText);
  }, [settings.defaultText]);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleBackgroundChange = (event) => {
    const selectedPreset = backgroundPresets.find(p => p.name === event.target.value);
    if (selectedPreset) {
      setBackground(selectedPreset.src);
      setDisplayedImage(selectedPreset.src); // Update the displayed image when background changes
      setSettings(selectedPreset.settings);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!fontLoaded) {
      alert('Font is still loading, please wait...');
      return;
    }

    try {
      // The background now comes from the state, which can be changed by the user
      const result = await mergeTextWithImage(text, background, settings);
      const result2 = await addWatermark("Poké-Meme.com", result, settings);
      setDisplayedImage(result2);
    } catch (error) {
      console.error('Error processing image:', error);
    }
  };


  return (
    <Container fluid className="Image-Processor bg-dark text-white p-3  rounded border"> {/* Apply dark theme and rounded corners */}
      <Row>
        <Col >
        <form className="Image-Processor" onSubmit={handleFormSubmit}>
            <input type="text" value={text} onChange={handleTextChange} placeholder={placeholderText}
              className="form-control" /> {/* Bootstrap form control */}
          <div className="my-3">
            <select onChange={handleBackgroundChange} className="form-select"> {/* Bootstrap form select */}
              {backgroundPresets.map((preset, index) => (
                <option key={index} value={preset.name}>
                  {preset.name}
                </option>
              ))}
            </select>
          </div>
          <button type="submit" disabled={!fontLoaded} className="btn btn-light">Generate Image</button> {/* Bootstrap button */}
        </form>
        <div className="">
          {displayedImage && (
            <div className='ProcessedImage'>
              <br />
              <img src={displayedImage} alt="Displayed" className="img-fluid rounded" /> {/* Responsive image with rounded corners */}
            </div>
          )}
          </div>
          </Col>
      </Row>
    </Container>
  );
};

export default ImageProcessor;
