//import logo from './logo.svg';
import './App.css';
import './fonts.css';
import NavigationBar from './components/NavigationBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import MemeList from './components/MemeList';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageProcessor from './components/ImageProcessor';
import AboutPage from './components/AboutPage';
import { Col, Container,Row } from 'react-bootstrap';

function App() {
  return (
    <Router>
      <div className="App">
        <NavigationBar />
        <Container fluid className="bg-dark text-white py-3">
          <Row >
            <Col md={{ span: 6, offset: 3 }} >
              <Routes>
                <Route path="/" element={<ImageProcessor />} />
                <Route path="/generator" element={<ImageProcessor />} />
                <Route path="/about" element={<AboutPage />} />
              </Routes>
            </Col>
          </Row>
        </Container>
      </div>
    </Router>
  );
}

export default App;
