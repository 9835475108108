import { Link } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';

const NavigationBar = () => {
  return (
    <Navbar className='Navbar text-black' bg="" expand="lg">
      <Container >
        <Navbar.Brand className='NavbarFont NavbarBrand' as={Link} to="/" >Poké-Meme</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className='NavbarFont' as={Link} to="/generator">Generator</Nav.Link>
            <Nav.Link className='NavbarFont' as={Link} to="/about">About</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
