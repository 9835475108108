import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const AboutPage = () => {
    return (
        <Container fluid className="bg-dark text-white py-5">
            <Row>
                <Col >
                    <h1 className='PokeFont'>About Poké-Meme</h1>
                    <p className='PokeFont'>Poké-Meme is intended to be a simple image generator that puts your text into the PokéMon games in the appropriate font style and size. I found a lack of generators that looked like actual screenshots of the game, so this is my solution.</p>
                    <p className='PokeFont'>If you have any suggestions for other similar generators, please email suggestions@poke-meme.com</p>
                    <p className='PokeFont'>Poké-Meme is not affiliated with Nintendo, Game Freak, or The Pokémon Company. </p> 
                    <p className='PokeFont'>Poké-Meme.com was created by Nicole Demera.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutPage;