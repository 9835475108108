// Define the presets for background images and their corresponding font settings.

const backgroundPresets = [
  {
    name: 'Proffesor Oak GBA',
    src: '/assets/ProfOak/Prof Oak GBA Blank.png',
    settings: {
        fontSize: 75,
        fontFamily: 'GBA Edit',
        fontColor:'#636363',
        shadowColor:'#d6d6ce',
        maxWidth: 1200,
        lineHeight: 100,
        margin: 10,
        maxRows: 2,
        anchorX: 90,
        anchorY: 736,
        defaultText: 'Welcome to the world of POKéMON!',
        trailingImage: '/assets/Trails/GBAContinue.png',
        trailingYoffset: 30,
        trailingXoffset: 6,
        watermarkOpacity: 0.1,
        watermarkFontSize: 35,
    },
  },
  {
    name: 'Proffesor Oak GBC',
    src: '/assets/ProfOak/Prof Oak GBC Blank.png',
    settings: {
        fontSize: 58,
        fontFamily: 'Pokemon Edit',
        fontColor: 'black',
        maxWidth: 1000,
        lineHeight: 100,
        margin: 10,
        maxRows: 2,
        anchorX: 50,
        anchorY: 750,
        defaultText: 'Welcome to the world of POKéMON!',
        watermarkOpacity: 0.05,
        watermarkFontSize: 20,
    },
  },
];

export default backgroundPresets;
